import React from 'react';
import './ClientHome.css';
import image1 from '../components/images/c1.png';
import image2 from '../components/images/c2.png';
import image3 from '../components/images/c3.png';
import image4 from '../components/images/c4.png';
import image5 from '../components/images/c5.png';
import image6 from '../components/images/c6.png';
import { useNavigate } from "react-router-dom";
import { LuMoveRight } from "react-icons/lu";

function ClientHome({ scrollToTop }) {
    const navigate = useNavigate('');
    const Home = [
        { image: image1, name: "" },
        { image: image2, name: "" },
        { image: image3, name: "" },
        { image: image4, name: "" },
        { image: image5, name: "" },
        { image: image6, name: "" },
    ];

    const handleReadMoreClick = () => {
        scrollToTop();
        navigate("/about"); // Change "/client-page" to the appropriate route
    };

    return (
        <div className="client-home">
            <h1>Our Client</h1>
            <div className="home-container">
                {Home.map((review, index) => (
                    <div key={index} className="review-card">
                        <img src={review.image} alt={`Client ${index + 1}`} className="client-image" />
                        <p className="client-name">{review.name}</p>
                    </div>
                ))}
            </div>
            <div className="read-more-container">
                <button
                    onClick={handleReadMoreClick}
                    className="bg-[white] w-max h-[70px] pl-[20px] pr-[50px] flex flex-row items-center gap-[18px] border border-[white] hover:border-[#d4d4d4] group an"
                >
                    <p className="b1">Read More</p>
                    <LuMoveRight className="text-[#333333] group-hover:translate-x-2 an" />
                </button>
            </div>
        </div>
    );
}

export default ClientHome;
