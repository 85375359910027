import React, { useState } from "react";
import { LuMoveLeft, LuMoveRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

// Import images
import projectImage1 from "../../src/components/images/P7.jpg";
import projectImage2 from "../../src/components/images/P6.jpg";
import projectImage3 from "../../src/components/images/P5.jpg";
import projectImage4 from "../../src/components/images/P4.jpg";
import projectImage5 from "../../src/components/images/P3.jpg";
import projectImage6 from "../../src/components/images/P2.jpg";
import projectImage7 from "../../src/components/images/P1.jpg";

function Projects({ scrollToTop }) {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const projects = [
    {
      title: "GE Power India Ltd)",
      description: ".",
      image: projectImage1,
    },
    {
      title: "AIC Iron Industries Pvt.Ltd(Adhunik Group)(Purulia)",
      description:
        "Civil construction work of 2x25 MT SMS & 600 TPD DRI along with infrastructure development work.",
      image: projectImage2,
    },
    {
      title: "Rungta Mines Ltd.(Odisha)",
      description: "Civil Construction of 2x20 TON SMS Project.",
      image: projectImage3,
    },
    {
      title: "Sonar Casting Ltd.(KMSM)(Andal)",
      description: "Civil Construction of 12000 TPA Ductile Iron Foundry.",
      image: projectImage4,
    },
    {
      title: "NEO Metaliks Ltd. (Durgapur)",
      description: "Civil construction work of 10 MW power plant.",
      image: projectImage5,
    },
    {
      title: "Urban Galaxy India Pvt. Ltd. (Durgapur)",
      description:
        "Civil construction work of B+G+11 commercial cum residential building at Gopalmath.",
      image: projectImage6,
    },
    {
      title: "Giridhan Metal Pvt Ltd. (SAI Group) (Jamuria)",
      description: "Civil Construction of 5x25 MT SMS and 46 MW Power Plant.",
      image: projectImage7,
    },
  ];

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="px-[130px] max-[1080px]:px-[10px] w-full h-max flex flex-col items-start pb-[50px]">
      <div className="flex flex-row justify-start gap-[20px] pb-[30px]">
        <div className="h1">Our</div>
        <div className="h2">Projects</div>
      </div>

      <div className="relative w-full h-[60vh] max-[1080px]:h-max overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {projects.map((project, index) => (
            <div
              key={index}
              className="w-full h-full flex-none flex flex-row max-[1080px]:h-max"
            >
              <div className="w-full h-full bg-[#F3F3F3] flex flex-row max-[1080px]:h-max">
                <div className="h-full w-[55%] max-[1080px]:hidden overflow-clip">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover scale-90 hover:scale-100 rounded-lg transition-transform duration-300"
                  />
                </div>
                <div className="h-full w-[45%] max-[1080px]:w-full flex flex-col justify-between p-[30px] max-[1080px]:gap-[10px] max-[1080px]:h-max">
                  <p className="h4">{project.title}</p>
                  <p className="txt">{project.description}</p>
                  <button
                    onClick={() => {
                      scrollToTop();
                      navigate(`/project/${index}`);
                    }}
                    className="bg-[white] w-max h-[70px] pl-[20px] pr-[50px] flex flex-row items-center gap-[18px] border border-[white] hover:border-[#d4d4d4] group transition-all duration-300"
                  >
                    <p className="b1">View More</p>
                    <LuMoveRight className="text-[#333333] group-hover:translate-x-2 transition-transform duration-300" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 w-[53px] h-[53px] bg-[#F9F9F9] hover:bg-[#e9e9e9] transition-all duration-300 flex items-center justify-center group"
          onClick={prevSlide}
        >
          <LuMoveLeft className="text-[#333333] group-hover:-translate-x-1 transition-transform duration-300" />
        </button>
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[53px] h-[53px] bg-[#F9F9F9] hover:bg-[#e9e9e9] transition-all duration-300 flex items-center justify-center group"
          onClick={nextSlide}
        >
          <LuMoveRight className="text-[#333333] group-hover:translate-x-1 transition-transform duration-300" />
        </button>
      </div>

      <div className="w-full h-max flex flex-row justify-center items-center gap-[30px] pt-[20px]">
        {projects.map((_, index) => (
          <div
            key={index}
            className={`h-[10px] w-[10px] rounded-full ${
              currentIndex === index ? "bg-[#333]" : "bg-[#d4d4d4]"
            }`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
