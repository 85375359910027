import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCircle, FaIndustry, FaSave, FaTools, FaTruck } from 'react-icons/fa';
import './ServiceHome.css';

const ServiceHome = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/ser'); // Adjust the route as needed
    };

   
    const services = [
        {
            icon: <FaIndustry />,
            title: 'Project Management :',
            description: 'Expert management for seamless project execution and operational success.',
        },
        {
            icon: <FaTools />,
            title: 'Manufacturing :',
            description: 'Advanced manufacturing services with top-tier quality and efficiency.',
        },
        {
            icon: <FaTruck />,
            title: 'Design :',
            description: 'Innovative design solutions that blend aesthetics with functionality.',
        },
        {
            icon: <FaSave />,
            title: 'Retrofit and Renovation :',
            description: 'Enhancing structures with modern, efficient renovation solutions.',
        },
        {
            icon: <FaCircle />,
            title: 'Installation :',
            description: 'Precise, professional installation services with attention to detail.',
        },
    ];
    
    

    return (
        <div className="services-section">
            <h1>Our Services</h1>
            <div className="services-container">
                {services.map((service, index) => (
                    <div key={index} className="service-card">
                        <div className="service-icon">{service.icon}</div>
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                        <button className="service-button" onClick={handleButtonClick}>
                            Learn More
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceHome;
