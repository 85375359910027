import React, { useState } from 'react';
import './Project.css'; // Import the CSS for styling
import projectImage1 from "../components/images/P2.jpg";
import projectImage2 from "../components/images/P6.jpg";
import projectImage3 from "../components/images/P4.jpg";
import projectImage4 from "../components/images/P5.jpg";
import projectImage5 from "../components/images/P7.jpg";
import projectImage6 from "../components/images/P8.jpg";

// Sample project data
const projects = [
    {
        id: 1,
        name: 'AIC Iron Industries Pvt.Ltd(Adhunik Group)(Purulia)',
        description: 'Civil construction work of 2x25 MT SMS & 600 TPD DRI along with infrastructure development work.',
        details: 'Detailed information about Project Alpha, including phases, milestones, and key deliverables.',
        image: projectImage1,
    },
    {
        id: 2,
        name: 'Rungta Mines Ltd.(Odisha)',
        description: 'This is the description of Project Beta, highlighting its goals and expected outcomes.',
        details: 'Detailed information about Project Beta, including technical specifications, resources, and timelines.',
        image: projectImage2,
    },
    {
        id: 3,
        name: 'NEO Metaliks Ltd. (Durgapur)',
        description: 'This is the description of Project Gamma, focusing on its impact and expected results.',
        details: 'Detailed information about Project Gamma, including budget, team members, and risk assessments.',
        image: projectImage3,
    },
    {
      id: 4,
      name: 'Project Alpha',
      description: 'This is the description of Project Alpha, detailing its scope, objectives, and timeline.',
      details: 'Detailed information about Project Alpha, including phases, milestones, and key deliverables.',
      image: projectImage4,
  },
  {
      id: 5,
      name: 'Project Beta',
      description: 'This is the description of Project Beta, highlighting its goals and expected outcomes.',
      details: 'Detailed information about Project Beta, including technical specifications, resources, and timelines.',
      image: projectImage5,
  },
  {
      id: 6,
      name: 'Project Gamma',
      description: 'This is the description of Project Gamma, focusing on its impact and expected results.',
      details: 'Detailed information about Project Gamma, including budget, team members, and risk assessments.',
      image: projectImage6,
  }
];

function Project() {
    const [selectedProject, setSelectedProject] = useState(projects[0]);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        document.getElementById('project-details').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="project-container">
            <div className="project-list">
                <h2>Projects</h2>
                <ul>
                    {projects.map(project => (
                        <li key={project.id} onClick={() => handleProjectClick(project)}>
                            {project.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="project-details" id="project-details">
                <h2>{selectedProject.name}</h2>
                <img src={selectedProject.image} alt={selectedProject.name} className="project-image animated-image" />
                <p>{selectedProject.description}</p>
                <p>{selectedProject.details}</p>
            </div>
        </div>
    );
}

export default Project;
