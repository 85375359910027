import React, { useState } from 'react';
import './About.css';

// Sample product data
const products = [
    {
        id: 1,
        name: 'GE Power India Ltd',
        text: 'For GE Power India Ltd., we provided comprehensive pre-engineered solutions, including the supply of ducts, silos, and support structures for the Electrostatic Precipitator (ESP) systems, both internal and external. Our work ensured that these critical components met the highest standards of durability and performance, essential for efficient power generation. By delivering robust and precisely engineered structures, we contributed to the enhancement of operational reliability and environmental compliance in their power plants..',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.20.18_5b223cac.jpg")
    },
    {
        id: 2,
        name: 'Thermax Enviro',
        text: 'For Thermax Enviro, we supplied a range of pre-engineered components, including ESP support structures, ducts, dampers, ESP externals, and bag filters. Our precision-engineered solutions were crucial in enhancing the efficiency and environmental compliance of their emission control systems. By delivering durable and reliable structures, we played a vital role in supporting Thermax Enviro’s mission to provide high-performance, eco-friendly industrial solutions, ensuring operational excellence and sustainability in their projects..',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.19.24_bf56bbf5.jpg")
    },
    {
        id: 3,
        name: ' Thermax C&H',
        text: 'For Thermax C&H, we provided a range of pre-engineered structures, including coal burners, lime bunkers, sand bunkers, and Air Preheater (APH) structures. Our expertly crafted solutions were essential in optimizing the storage and processing systems within their operations. By delivering robust and precisely engineered components, we contributed to the efficiency, safety, and reliability of their thermal processes, supporting Thermax C&H’s commitment to delivering top-tier industrial solutions..',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.19.24_bf56bbf5.jpg")
    },
    {
        id: 4,
        name: 'ISGEC Heavy Engineering',
        text: 'For ISGEC Heavy Engineering, we delivered a comprehensive set of pre-engineered solutions, including boiler structures, bunkers, expansion joints/bellows, buckstays, casing, tanks, and hoppers. These components were meticulously designed to enhance the structural integrity and efficiency of their industrial systems. Our precision engineering ensured that each element met the highest standards of durability and performance, supporting ISGEC’s commitment to delivering reliable and efficient heavy engineering projects across various industries..',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.20.47_feaf2f3d.jpg")
    },
    {
        id: 5,
        name: 'Clyde Bergemann India',
        text: 'For Clyde Bergemann India, we supplied high-quality ESP externals, essential for their advanced emission control systems. Our pre-engineered components were meticulously crafted to ensure durability, efficiency, and seamless integration into their existing infrastructure. By delivering these reliable and precision-engineered ESP externals, we contributed to enhancing the environmental performance and operational reliability of Clyde Bergemann India’s projects, supporting their commitment to sustainable and efficient industrial solutions..',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.21.20_b748b4d9.jpg")
    },
    {
        id: 6,
        name: 'Elex India',
        text: 'For Elec India, we supplied expertly engineered ESP externals and grinders, crucial components for their emission control systems. Our pre-engineered solutions were designed to meet the highest industry standards, ensuring efficient performance and durability. By delivering these robust and precisely crafted components, we played a key role in enhancing the operational effectiveness and environmental compliance of Elec India’s projects, supporting their mission to provide reliable and sustainable industrial solutions..',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.21.05_bb910695.jpg")
    },
    {
        id: 7,
        name: 'GEA Process Engineering',
        text: 'For GEA Process Engineering, we provided a comprehensive range of pre-engineered solutions, including ducts, silos, structures, and poppet damper ducts. These components were designed and manufactured to meet the highest standards of precision and durability, ensuring seamless integration into their complex processing systems. Our contributions played a key role in optimizing the efficiency and reliability of their operations, supporting GEA Process Engineering’s commitment to delivering innovative and high-performance industrial solutions.',
        logo: require("../../src/components/images/WhatsApp Image 2024-08-22 at 15.21.32_0d96fe90.jpg")
    },
    {
        id: 8,
        name: 'Jindal CO AL A/C',
        text: 'For Jindal CO AL A/C, we successfully completed the design and construction of a state-of-the-art pot room duct for their aluminum smelting operations. This project, executed for Alstom Projects India, involved precise engineering to ensure efficient airflow and heat management within the facility. Our expertise in pre-engineered buildings allowed us to deliver a robust and energy-efficient solution, enhancing operational efficiency while adhering to stringent industry standards and timelines..',
        logo: require("../../src/components/images/jindal.png")
    }
];

function About() {
    const [selectedProduct, setSelectedProduct] = useState(products[0]);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        document.getElementById('product-details').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="product-container">
            {/* Logo at the top-right corner based on selected product */}
            <div className="logo-container">
                <img src={selectedProduct.logo} alt="Product Logo" className="logo" />
            </div>

            <div className="product-list">
                <h2>Clients</h2>
                <ul>
                    {products.map(product => (
                        <li key={product.id} onClick={() => handleProductClick(product)}>
                            {product.name}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="product-details" id="product-details">
                <h2>{selectedProduct.name}</h2>
                <p>{selectedProduct.text}</p>
            </div>
        </div>
    );
}

export default About;
