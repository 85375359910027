import React from 'react';

const WhyPEB = () => {
  const containerStyle = {
    fontFamily: 'sans-serif',
    padding: '20px',
  };

  const headingStyle = {
    textAlign: 'center',
    fontSize: '32px',
    marginBottom: '20px',
  };

  const columnStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };

  const column = (bgColor, text) => ({
    flex: 1,
    backgroundColor: bgColor,
    padding: '20px',
    margin: '10px',
    borderRadius: '8px',
    color: '#fff',
  });

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Why PEB?</h1>
      <div style={columnStyle}>
        <div style={column('#ffbe03', 'PEB in React JS')}>
          <h2>Design and Quality</h2>
          <p>
            {/* Add content for PEB in React JS here */}
           <p>Core Strength: Design and delivery are our strong suits; we deliver on time, every time.</p> 
           <p>High-Quality Materials: We use leading-edge technology and quality materials for manufacturing.</p> 
           <p>Customization Options: Our buildings offer options for flamboyant or sober looks, with roofs designed in various heights.</p> 
           <p>Variety and Expansion: We provide options for different appearances and can expand as per customer needs.</p> 
          </p>
        </div>
        <div style={column('#fc238c', 'PEB in Other Technologies')}>
          <h2>Efficiency and Support</h2>
          <p>
            {/* Add content for PEB in other technologies here */}
            <p> Competent Workforce: Our sophisticated and expeditious manpower ensures quality and low-maintenance results.</p> 
            <p>Nominal Costs: We offer nominal maintenance and repair costs.</p> 
            <p>After-Sales Support: We provide efficient after-sales support, ensuring continuous customer satisfaction.</p> 
            <p>Erection and Maintenance: We handle the erection process efficiently, with provision for easy maintenance and repairs.</p> 
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyPEB;


