// src/Client.js
import React, { useState } from "react";
import "./Client.css";  // Import the CSS file

function Client() {
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const logos = [
    { src: "logo1.png", alt: "Company 1" },
    { src: "logo2.png", alt: "Company 2" },
    { src: "logo3.png", alt: "Company 3" },
    { src: "logo1.png", alt: "Company 1" },
    { src: "logo2.png", alt: "Company 2" },
    { src: "logo3.png", alt: "Company 3" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const newReview = { name, review, rating };
    setReviews([...reviews, newReview]);
    setName("");
    setReview("");
    setRating(0);
  };

  return (
    <div style={{ padding: "20px",color: "white",maxWidth: "800px", margin: "0 auto" }}>
      <h1>Client Reviews</h1>

      <form onSubmit={handleSubmit} style={{ marginBottom: "30px" }}>
        <div style={{ marginBottom: "10px" }}>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{ marginLeft: "10px", padding: "5px", width: "100%" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Review:</label>
          <textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            required
            style={{ marginLeft: "10px", padding: "5px", width: "100%" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Rating:</label>
          <input
            type="number"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
            min="1"
            max="5"
            required
            style={{ marginLeft: "10px", padding: "5px", width: "100px" }}
          />
        </div>
        <button type="submit" style={{ padding: "10px 20px", cursor: "pointer" }}>
          Submit Review
        </button>
      </form>

      <div style={{ marginBottom: "30px" }}>
        <h2>Client Reviews</h2>
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <div key={index} className="review-card">
              <h4>{review.name}</h4>
              <p>{review.review}</p>
              <p>Rating: {review.rating} / 5</p>
            </div>
          ))
        ) : (
          <p>No reviews yet. Be the first to leave one!</p>
        )}
      </div>

      <div>
        <h2>Our Clients</h2>
        <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
          {logos.map((logo, index) => (
            <img key={index} src={logo.src} alt={logo.alt} className="logo" />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Client;
